/*
@tailwind base;

@tailwind components;
*/

.pointer-events-none {
  pointer-events: none
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.collapse {
  visibility: collapse
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.inset-0 {
  inset: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.right-0 {
  right: 0
}

.top-0 {
  top: 0
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-50 {
  z-index: 50
}

.z-99 {
  z-index: 99
}

.z-999 {
  z-index: 999
}

.z-9999 {
  z-index: 9999
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.col-span-1 {
  grid-column: span 1 / span 1
}

.col-span-10 {
  grid-column: span 10 / span 10
}

.col-span-12 {
  grid-column: span 12 / span 12
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.col-span-3 {
  grid-column: span 3 / span 3
}

.col-span-4 {
  grid-column: span 4 / span 4
}

.col-span-5 {
  grid-column: span 5 / span 5
}

.col-span-6 {
  grid-column: span 6 / span 6
}

.col-span-8 {
  grid-column: span 8 / span 8
}

.col-span-9 {
  grid-column: span 9 / span 9
}

.float-left {
  float: left
}

.m-16 {
  margin: 1.6rem
}

.m-8 {
  margin: 0.8rem
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

.-mx-24 {
  margin-left: -2.4rem;
  margin-right: -2.4rem
}

.-mx-8 {
  margin-left: -0.8rem;
  margin-right: -0.8rem
}

.mx-0 {
  margin-left: 0;
  margin-right: 0
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.mx-232 {
  margin-left: 23.2rem;
  margin-right: 23.2rem
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.my-2 {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

.my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.-mb-10 {
  margin-bottom: -1.0rem
}

.-mb-36 {
  margin-bottom: -3.6rem
}

.-mb-8 {
  margin-bottom: -0.8rem
}

.-ml-12 {
  margin-left: -1.2rem
}

.-ml-24 {
  margin-left: -2.4rem
}

.-mr-6 {
  margin-right: -0.6rem
}

.-mt-128 {
  margin-top: -12.8rem
}

.-mt-16 {
  margin-top: -1.6rem
}

.-mt-24 {
  margin-top: -2.4rem
}

.-mt-4 {
  margin-top: -0.4rem
}

.-mt-48 {
  margin-top: -4.8rem
}

.-mt-96 {
  margin-top: -9.6rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.mb-2 {
  margin-bottom: 0.2rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.mb-40 {
  margin-bottom: 4rem
}

.mb-48 {
  margin-bottom: 4.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.mb-80 {
  margin-bottom: 8rem
}

.mb-96 {
  margin-bottom: 9.6rem
}

.ml-0 {
  margin-left: 0
}

.ml-10 {
  margin-left: 1.0rem
}

.ml-104 {
  margin-left: 10.4rem
}

.ml-12 {
  margin-left: 1.2rem
}

.ml-136 {
  margin-left: 13.6rem
}

.ml-16 {
  margin-left: 1.6rem
}

.ml-168 {
  margin-left: 16.8rem
}

.ml-2 {
  margin-left: 0.2rem
}

.ml-20 {
  margin-left: 2rem
}

.ml-24 {
  margin-left: 2.4rem
}

.ml-32 {
  margin-left: 3.2rem
}

.ml-4 {
  margin-left: 0.4rem
}

.ml-56 {
  margin-left: 5.6rem
}

.ml-8 {
  margin-left: 0.8rem
}

.ml-80 {
  margin-left: 8rem
}

.ml-auto {
  margin-left: auto
}

.mr-0 {
  margin-right: 0
}

.mr-12 {
  margin-right: 1.2rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mr-2 {
  margin-right: 0.2rem
}

.mr-24 {
  margin-right: 2.4rem
}

.mr-32 {
  margin-right: 3.2rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mr-40 {
  margin-right: 4rem
}

.mr-56 {
  margin-right: 5.6rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mt-0 {
  margin-top: 0
}

.mt-10 {
  margin-top: 1.0rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mt-2 {
  margin-top: 0.2rem
}

.mt-20 {
  margin-top: 2rem
}

.mt-24 {
  margin-top: 2.4rem
}

.mt-28 {
  margin-top: 2.8rem
}

.mt-32 {
  margin-top: 3.2rem
}

.mt-4 {
  margin-top: 0.4rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mt-52 {
  margin-top: 5.2rem
}

.mt-64 {
  margin-top: 6.4rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mt-auto {
  margin-top: auto
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.table-row {
  display: table-row
}

.grid {
  display: grid
}

.list-item {
  display: list-item
}

.hidden {
  display: none
}

.aspect-video {
  aspect-ratio: 16 / 9
}

.h-0 {
  height: 0
}

.h-12 {
  height: 1.2rem
}

.h-128 {
  height: 12.8rem
}

.h-136 {
  height: 13.6rem
}

.h-16 {
  height: 1.6rem
}

.h-160 {
  height: 16rem
}

.h-2 {
  height: 0.2rem
}

.h-20 {
  height: 2rem
}

.h-200 {
  height: 20rem
}

.h-24 {
  height: 2.4rem
}

.h-256 {
  height: 25.6rem
}

.h-28 {
  height: 2.8rem
}

.h-288 {
  height: 28.8rem
}

.h-32 {
  height: 3.2rem
}

.h-320 {
  height: 32rem
}

.h-36 {
  height: 3.6rem
}

.h-360 {
  height: 36rem
}

.h-40 {
  height: 4rem
}

.h-44 {
  height: 4.4rem
}

.h-48 {
  height: 4.8rem
}

.h-512 {
  height: 51.2rem
}

.h-56 {
  height: 5.6rem
}

.h-64 {
  height: 6.4rem
}

.h-72 {
  height: 7.2rem
}

.h-8 {
  height: 0.8rem
}

.h-96 {
  height: 9.6rem
}

.h-auto {
  height: auto
}

.h-full {
  height: 100%
}

.h-header {
  height: 12rem
}

.h-screen {
  height: 100vh
}

.max-h-232 {
  max-height: 23.2rem
}

.max-h-256 {
  max-height: 25.6rem
}

.max-h-320 {
  max-height: 32rem
}

.max-h-full {
  max-height: 100%
}

.min-h-0 {
  min-height: 0
}

.min-h-160 {
  min-height: 16rem
}

.min-h-168 {
  min-height: 16.8rem
}

.min-h-20 {
  min-height: 2rem
}

.min-h-24 {
  min-height: 2.4rem
}

.min-h-28 {
  min-height: 2.8rem
}

.min-h-32 {
  min-height: 3.2rem
}

.min-h-320 {
  min-height: 32rem
}

.min-h-40 {
  min-height: 4rem
}

.min-h-44 {
  min-height: 4.4rem
}

.min-h-48 {
  min-height: 4.8rem
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-72 {
  min-height: 7.2rem
}

.min-h-84 {
  min-height: 8.4rem
}

.min-h-header {
  min-height: 12rem
}

.w-0 {
  width: 0
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-1\/4 {
  width: 25%
}

.w-104 {
  width: 10.4rem
}

.w-11\/12 {
  width: 91.666667%
}

.w-12 {
  width: 1.2rem
}

.w-128 {
  width: 12.8rem
}

.w-136 {
  width: 13.6rem
}

.w-16 {
  width: 1.6rem
}

.w-160 {
  width: 16rem
}

.w-168 {
  width: 16.8rem
}

.w-192 {
  width: 19.2rem
}

.w-2 {
  width: 0.2rem
}

.w-20 {
  width: 2rem
}

.w-200 {
  width: 20rem
}

.w-216 {
  width: 21.6rem
}

.w-224 {
  width: 22.4rem
}

.w-232 {
  width: 23.2rem
}

.w-24 {
  width: 2.4rem
}

.w-256 {
  width: 25.6rem
}

.w-28 {
  width: 2.8rem
}

.w-32 {
  width: 3.2rem
}

.w-320 {
  width: 32rem
}

.w-36 {
  width: 3.6rem
}

.w-360 {
  width: 36rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-512 {
  width: 51.2rem
}

.w-56 {
  width: 5.6rem
}

.w-64 {
  width: 6.4rem
}

.w-72 {
  width: 7.2rem
}

.w-720 {
  width: 72rem
}

.w-8 {
  width: 0.8rem
}

.w-80 {
  width: 8rem
}

.w-800 {
  width: 80rem
}

.w-88 {
  width: 8.8rem
}

.w-92 {
  width: 9.2rem
}

.w-96 {
  width: 9.6rem
}

.w-fit {
  width: fit-content
}

.w-full {
  width: 100%
}

.w-xs {
  width: 32rem
}

.min-w-0 {
  min-width: 0
}

.min-w-104 {
  min-width: 10.4rem
}

.min-w-128 {
  min-width: 12.8rem
}

.min-w-136 {
  min-width: 13.6rem
}

.min-w-160 {
  min-width: 16rem
}

.min-w-168 {
  min-width: 16.8rem
}

.min-w-192 {
  min-width: 19.2rem
}

.min-w-20 {
  min-width: 2rem
}

.min-w-200 {
  min-width: 20rem
}

.min-w-224 {
  min-width: 22.4rem
}

.min-w-256 {
  min-width: 25.6rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-48 {
  min-width: 4.8rem
}

.min-w-60 {
  min-width: 6rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-640 {
  min-width: 64rem
}

.min-w-80 {
  min-width: 8rem
}

.min-w-88 {
  min-width: 8.8rem
}

.min-w-96 {
  min-width: 9.6rem
}

.min-w-full {
  min-width: 100%
}

.max-w-0 {
  max-width: 0
}

.max-w-104 {
  max-width: 10.4rem
}

.max-w-136 {
  max-width: 13.6rem
}

.max-w-160 {
  max-width: 16rem
}

.max-w-168 {
  max-width: 16.8rem
}

.max-w-192 {
  max-width: 19.2rem
}

.max-w-200 {
  max-width: 20rem
}

.max-w-232 {
  max-width: 23.2rem
}

.max-w-256 {
  max-width: 25.6rem
}

.max-w-320 {
  max-width: 32rem
}

.max-w-40 {
  max-width: 4rem
}

.max-w-488 {
  max-width: 48.8rem
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-80 {
  max-width: 8rem
}

.max-w-lg {
  max-width: 80rem
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-initial {
  flex: 0 1 auto
}

.flex-shrink-0 {
  flex-shrink: 0
}

.table-fixed {
  table-layout: fixed
}

.border-collapse {
  border-collapse: collapse
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

.animate-spin {
  animation: spin 1s linear infinite
}

.cursor-default {
  cursor: default
}

.cursor-not-allowed {
  cursor: not-allowed
}

.cursor-pointer {
  cursor: pointer
}

.resize {
  resize: both
}

.list-disc {
  list-style-type: disc
}

.break-inside-avoid {
  break-inside: avoid
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr))
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr))
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr))
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.items-stretch {
  align-items: stretch
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-16 {
  gap: 1.6rem
}

.gap-2 {
  gap: 0.2rem
}

.gap-4 {
  gap: 0.4rem
}

.gap-8 {
  gap: 0.8rem
}

.gap-x-12 {
  column-gap: 1.2rem
}

.gap-x-16 {
  column-gap: 1.6rem
}

.gap-x-20 {
  column-gap: 2rem
}

.gap-x-4 {
  column-gap: 0.4rem
}

.gap-x-8 {
  column-gap: 0.8rem
}

.gap-y-12 {
  row-gap: 1.2rem
}

.gap-y-16 {
  row-gap: 1.6rem
}

.gap-y-4 {
  row-gap: 0.4rem
}

.gap-y-6 {
  row-gap: 0.6rem
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.2rem * var(--tw-space-x-reverse));
  margin-left: calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.6rem * var(--tw-space-x-reverse));
  margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.2rem * var(--tw-space-x-reverse));
  margin-left: calc(0.2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.4rem * var(--tw-space-x-reverse));
  margin-left: calc(0.4rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.8rem * var(--tw-space-x-reverse));
  margin-left: calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-88 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8.8rem * var(--tw-space-x-reverse));
  margin-left: calc(8.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse))
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.2rem * var(--tw-space-y-reverse))
}

.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.6rem * var(--tw-space-y-reverse))
}

.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}

.space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.4rem * var(--tw-space-y-reverse))
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.4rem * var(--tw-space-y-reverse))
}

.space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse))
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.8rem * var(--tw-space-y-reverse))
}

.divide-paper > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(247 247 247 / var(--tw-divide-opacity))
}

.self-end {
  align-self: flex-end
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overflow-y-hidden {
  overflow-y: hidden
}

.overflow-y-scroll {
  overflow-y: scroll
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.whitespace-pre-line {
  white-space: pre-line
}

.whitespace-break-spaces {
  white-space: break-spaces
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal
}

.break-words {
  overflow-wrap: break-word
}

.rounded-16 {
  border-radius: 1.6rem
}

.rounded-2 {
  border-radius: .2rem
}

.rounded-20 {
  border-radius: 2rem
}

.rounded-24 {
  border-radius: 2.4rem
}

.rounded-32 {
  border-radius: 3.2rem
}

.rounded-4 {
  border-radius: .4rem
}

.rounded-6 {
  border-radius: .6rem
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: .8rem
}

.rounded-none {
  border-radius: 0
}

.rounded-b-8 {
  border-bottom-right-radius: .8rem;
  border-bottom-left-radius: .8rem
}

.rounded-t-24 {
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem
}

.rounded-t-8 {
  border-top-left-radius: .8rem;
  border-top-right-radius: .8rem
}

.border-0 {
  border-width: 0
}

.border-1 {
  border-width: 1px
}

.border-3 {
  border-width: 3px
}

.border-b-1 {
  border-bottom-width: 1px
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-l-1 {
  border-left-width: 1px
}

.border-l-4 {
  border-left-width: 4px
}

.border-r-1 {
  border-right-width: 1px
}

.border-t-1 {
  border-top-width: 1px
}

.border-t-2 {
  border-top-width: 2px
}

.border-dashed {
  border-style: dashed
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity))
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(238 238 238 / var(--tw-border-opacity))
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(224 224 224 / var(--tw-border-opacity))
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(189 189 189 / var(--tw-border-opacity))
}

.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity))
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity))
}

.border-neutral-100 {
  --tw-border-opacity: 1;
  border-color: rgb(246 246 246 / var(--tw-border-opacity))
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(244 67 54 / var(--tw-border-opacity))
}

.border-secondaryMain {
  --tw-border-opacity: 1;
  border-color: rgb(31 36 47 / var(--tw-border-opacity))
}

.border-selected {
  --tw-border-opacity: 1;
  border-color: rgb(220 237 248 / var(--tw-border-opacity))
}

.border-transparent {
  border-color: transparent
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 235 59 / var(--tw-border-opacity))
}

.border-b-paper {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(247 247 247 / var(--tw-border-opacity))
}

.border-l-errorMain {
  --tw-border-opacity: 1;
  border-left-color: rgb(224 17 95 / var(--tw-border-opacity))
}

.bg-errorMain {
  --tw-bg-opacity: 1;
  background-color: rgb(224 17 95 / var(--tw-bg-opacity))
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity))
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity))
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity))
}

.bg-paper {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity))
}

.bg-secondaryLight {
  --tw-bg-opacity: 1;
  background-color: rgb(124 128 139 / var(--tw-bg-opacity))
}

.bg-secondaryMain {
  --tw-bg-opacity: 1;
  background-color: rgb(31 36 47 / var(--tw-bg-opacity))
}

.bg-selected {
  --tw-bg-opacity: 1;
  background-color: rgb(220 237 248 / var(--tw-bg-opacity))
}

.bg-taskCompleted {
  --tw-bg-opacity: 1;
  background-color: rgb(128 191 228 / var(--tw-bg-opacity))
}

.bg-taskExpired {
  --tw-bg-opacity: 1;
  background-color: rgb(224 17 95 / var(--tw-bg-opacity))
}

.bg-taskInProgress {
  --tw-bg-opacity: 1;
  background-color: rgb(96 181 124 / var(--tw-bg-opacity))
}

.bg-taskOnDemand {
  background-color: rgba(224, 17, 95, 0.4)
}

.bg-taskOverdue {
  --tw-bg-opacity: 1;
  background-color: rgb(255 159 93 / var(--tw-bg-opacity))
}

.bg-taskPending {
  --tw-bg-opacity: 1;
  background-color: rgb(100 140 160 / var(--tw-bg-opacity))
}

.bg-tertiary-light-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 237 166 / var(--tw-bg-opacity))
}

.bg-todo {
  --tw-bg-opacity: 1;
  background-color: rgb(177 215 237 / var(--tw-bg-opacity))
}

.bg-transparent {
  background-color: transparent
}

.bg-warning {
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 47 / var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-opacity-25 {
  --tw-bg-opacity: 0.25
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5
}

.bg-opacity-75 {
  --tw-bg-opacity: 0.75
}

.object-contain {
  object-fit: contain
}

.object-cover {
  object-fit: cover
}

.object-center {
  object-position: center
}

.p-0 {
  padding: 0
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-2 {
  padding: 0.2rem
}

.p-24 {
  padding: 2.4rem
}

.p-4 {
  padding: 0.4rem
}

.p-6 {
  padding: 0.6rem
}

.p-8 {
  padding: 0.8rem
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.px-10 {
  padding-left: 1.0rem;
  padding-right: 1.0rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.px-6 {
  padding-left: 0.6rem;
  padding-right: 0.6rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.py-10 {
  padding-top: 1.0rem;
  padding-bottom: 1.0rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.py-2 {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem
}

.py-20 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.pb-0 {
  padding-bottom: 0
}

.pb-10 {
  padding-bottom: 1.0rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pb-4 {
  padding-bottom: 0.4rem
}

.pb-40 {
  padding-bottom: 4rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pb-88 {
  padding-bottom: 8.8rem
}

.pl-10 {
  padding-left: 1.0rem
}

.pl-12 {
  padding-left: 1.2rem
}

.pl-136 {
  padding-left: 13.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pl-2 {
  padding-left: 0.2rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pl-32 {
  padding-left: 3.2rem
}

.pl-4 {
  padding-left: 0.4rem
}

.pl-40 {
  padding-left: 4rem
}

.pl-44 {
  padding-left: 4.4rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pl-6 {
  padding-left: 0.6rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pr-0 {
  padding-right: 0
}

.pr-12 {
  padding-right: 1.2rem
}

.pr-16 {
  padding-right: 1.6rem
}

.pr-2 {
  padding-right: 0.2rem
}

.pr-20 {
  padding-right: 2rem
}

.pr-24 {
  padding-right: 2.4rem
}

.pr-28 {
  padding-right: 2.8rem
}

.pr-32 {
  padding-right: 3.2rem
}

.pr-4 {
  padding-right: 0.4rem
}

.pr-8 {
  padding-right: 0.8rem
}

.pt-10 {
  padding-top: 1.0rem
}

.pt-12 {
  padding-top: 1.2rem
}

.pt-128 {
  padding-top: 12.8rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pt-2 {
  padding-top: 0.2rem
}

.pt-20 {
  padding-top: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pt-32 {
  padding-top: 3.2rem
}

.pt-4 {
  padding-top: 0.4rem
}

.pt-40 {
  padding-top: 4rem
}

.pt-6 {
  padding-top: 0.6rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pt-80 {
  padding-top: 8rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.align-top {
  vertical-align: top
}

.text-10 {
  font-size: 1rem
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-15 {
  font-size: 1.5rem
}

.text-16 {
  font-size: 1.6rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-28 {
  font-size: 2.8rem
}

.text-32 {
  font-size: 3.2rem
}

.text-36 {
  font-size: 3.6rem
}

.text-40 {
  font-size: 4rem
}

.text-6 {
  font-size: 0.6rem
}

.text-7 {
  font-size: 0.7rem
}

.text-9 {
  font-size: 0.9rem
}

.text-96 {
  font-size: 9.6rem
}

.font-300 {
  font-weight: 300
}

.font-400 {
  font-weight: 400
}

.font-500 {
  font-weight: 500
}

.font-600 {
  font-weight: 600
}

.font-700 {
  font-weight: 700
}

.font-bold {
  font-weight: 700
}

.font-light {
  font-weight: 300
}

.font-medium {
  font-weight: 500
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.italic {
  font-style: italic
}

.ordinal {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.leading-4 {
  line-height: 1.6rem
}

.tracking-tighter {
  letter-spacing: -0.05em
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(34 41 47 / var(--tw-text-opacity))
}

.text-errorMain {
  --tw-text-opacity: 1;
  color: rgb(224 17 95 / var(--tw-text-opacity))
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(238 238 238 / var(--tw-text-opacity))
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(224 224 224 / var(--tw-text-opacity))
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(189 189 189 / var(--tw-text-opacity))
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(33 33 33 / var(--tw-text-opacity))
}

.text-paper {
  --tw-text-opacity: 1;
  color: rgb(247 247 247 / var(--tw-text-opacity))
}

.text-primaryDark {
  --tw-text-opacity: 1;
  color: rgb(3 49 77 / var(--tw-text-opacity))
}

.text-primaryLight {
  --tw-text-opacity: 1;
  color: rgb(128 191 228 / var(--tw-text-opacity))
}

.text-secondaryDark {
  --tw-text-opacity: 1;
  color: rgb(15 17 21 / var(--tw-text-opacity))
}

.text-secondaryLight {
  --tw-text-opacity: 1;
  color: rgb(124 128 139 / var(--tw-text-opacity))
}

.text-secondaryMain {
  --tw-text-opacity: 1;
  color: rgb(31 36 47 / var(--tw-text-opacity))
}

.text-taskCompleted {
  --tw-text-opacity: 1;
  color: rgb(128 191 228 / var(--tw-text-opacity))
}

.text-taskInProgress {
  --tw-text-opacity: 1;
  color: rgb(96 181 124 / var(--tw-text-opacity))
}

.text-taskOverdue {
  --tw-text-opacity: 1;
  color: rgb(255 159 93 / var(--tw-text-opacity))
}

.text-taskPending {
  --tw-text-opacity: 1;
  color: rgb(100 140 160 / var(--tw-text-opacity))
}

.text-taskUpcoming {
  --tw-text-opacity: 1;
  color: rgb(124 128 139 / var(--tw-text-opacity))
}

.text-warning {
  --tw-text-opacity: 1;
  color: rgb(245 208 47 / var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.underline {
  text-decoration-line: underline
}

.line-through {
  text-decoration-line: line-through
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.shadow-0 {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-1 {
  --tw-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12);
  --tw-shadow-colored: 0px 1px 3px 0px var(--tw-shadow-color), 0px 1px 1px 0px var(--tw-shadow-color), 0px 2px 1px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke
}

.duration-200 {
  transition-duration: 200ms
}

.duration-300 {
  transition-duration: 300ms
}

.line-clamp-1 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1
}

.text-small-light {
  font-size: 1.1rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(124 128 139 / var(--tw-text-opacity))
}

.text-small-dark {
  font-size: 1.1rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(31 36 47 / var(--tw-text-opacity))
}

.text-medium-dark {
  font-size: 1.3rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(31 36 47 / var(--tw-text-opacity))
}

.layout-box {
  border-radius: .8rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12);
  --tw-shadow-colored: 0px 1px 3px 0px var(--tw-shadow-color), 0px 1px 1px 0px var(--tw-shadow-color), 0px 2px 1px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.first\:block:first-child {
  display: block
}

.first\:pl-12:first-child {
  padding-left: 1.2rem
}

.first\:pl-4:first-child {
  padding-left: 0.4rem
}

.first\:pl-40:first-child {
  padding-left: 4rem
}

.first\:pt-0:first-child {
  padding-top: 0
}

.last\:mb-0:last-child {
  margin-bottom: 0
}

.last\:rounded-b-8:last-child {
  border-bottom-right-radius: .8rem;
  border-bottom-left-radius: .8rem
}

.last\:border-b-0:last-child {
  border-bottom-width: 0
}

.last\:pr-0:last-child {
  padding-right: 0
}

.last\:pr-32:last-child {
  padding-right: 3.2rem
}

.last\:pr-40:last-child {
  padding-right: 4rem
}

.hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(158 158 158 / var(--tw-border-opacity))
}

.hover\:bg-blue-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(207 216 220 / var(--tw-bg-opacity))
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity))
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity))
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 224 224 / var(--tw-bg-opacity))
}

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(189 189 189 / var(--tw-bg-opacity))
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity))
}

.hover\:bg-paper:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity))
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.hover\:bg-opacity-50:hover {
  --tw-bg-opacity: 0.5
}

.hover\:font-600:hover {
  font-weight: 600
}

.hover\:text-primaryLight:hover {
  --tw-text-opacity: 1;
  color: rgb(128 191 228 / var(--tw-text-opacity))
}

.hover\:text-secondaryMain:hover {
  --tw-text-opacity: 1;
  color: rgb(31 36 47 / var(--tw-text-opacity))
}

.hover\:underline:hover {
  text-decoration-line: underline
}

.hover\:shadow-0:hover {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-2:hover {
  --tw-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12);
  --tw-shadow-colored: 0px 1px 5px 0px var(--tw-shadow-color), 0px 2px 2px 0px var(--tw-shadow-color), 0px 3px 1px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

@media (min-width: 600px) {
  .sm\:order-1 {
    order: 1
  }

  .sm\:order-2 {
    order: 2
  }

  .sm\:-mt-24 {
    margin-top: -2.4rem
  }

  .sm\:mb-0 {
    margin-bottom: 0
  }

  .sm\:mb-12 {
    margin-bottom: 1.2rem
  }

  .sm\:flex {
    display: flex
  }

  .sm\:h-168 {
    height: 16.8rem
  }

  .sm\:h-44 {
    height: 4.4rem
  }

  .sm\:h-64 {
    height: 6.4rem
  }

  .sm\:h-header {
    height: 12rem
  }

  .sm\:min-h-168 {
    min-height: 16.8rem
  }

  .sm\:min-h-44 {
    min-height: 4.4rem
  }

  .sm\:min-h-64 {
    min-height: 6.4rem
  }

  .sm\:min-h-header {
    min-height: 12rem
  }

  .sm\:w-288 {
    width: 28.8rem
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .sm\:py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }
}

@media (min-width: 960px) {
  .md\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .md\:mt-0 {
    margin-top: 0
  }

  .md\:flex {
    display: flex
  }

  .md\:grid {
    display: grid
  }

  .md\:hidden {
    display: none
  }

  .md\:w-232 {
    width: 23.2rem
  }

  .md\:w-320 {
    width: 32rem
  }

  .md\:w-auto {
    width: auto
  }

  .md\:min-w-232 {
    min-width: 23.2rem
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:gap-16 {
    gap: 1.6rem
  }

  .md\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.6rem * var(--tw-space-x-reverse));
    margin-left: calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
  }

  .md\:divide-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .md\:border-r-1 {
    border-right-width: 1px
  }

  .md\:p-24 {
    padding: 2.4rem
  }

  .md\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .md\:px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem
  }

  .md\:pl-24 {
    padding-left: 2.4rem
  }

  .md\:pl-28 {
    padding-left: 2.8rem
  }

  .md\:pr-24 {
    padding-right: 2.4rem
  }

  .md\:pr-32 {
    padding-right: 3.2rem
  }

  .md\:pt-24 {
    padding-top: 2.4rem
  }
}

@media (min-width: 1280px) {
  .lg\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .lg\:mr-6 {
    margin-right: 0.6rem
  }

  .lg\:w-320 {
    width: 32rem
  }

  .lg\:w-360 {
    width: 36rem
  }

  .lg\:text-11 {
    font-size: 1.1rem
  }
}

@media (min-width: 1920px) {
  .xl\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9
  }

  .xl\:mr-8 {
    margin-right: 0.8rem
  }
}

.ltr\:left-0:where([dir="ltr"], [dir="ltr"] *) {
  left: 0
}

.ltr\:ml-4:where([dir="ltr"], [dir="ltr"] *) {
  margin-left: 0.4rem
}

.ltr\:ml-8:where([dir="ltr"], [dir="ltr"] *) {
  margin-left: 0.8rem
}

.rtl\:right-0:where([dir="rtl"], [dir="rtl"] *) {
  right: 0
}

.rtl\:mr-4:where([dir="rtl"], [dir="rtl"] *) {
  margin-right: 0.4rem
}

.rtl\:mr-8:where([dir="rtl"], [dir="rtl"] *) {
  margin-right: 0.8rem
}

@media print {
  .print\:visible {
    visibility: visible
  }

  .print\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .print\:block {
    display: block
  }

  .print\:hidden {
    display: none
  }

  .print\:h-auto {
    height: auto
  }

  .print\:max-w-full {
    max-width: 100%
  }

  .print\:overflow-auto {
    overflow: auto
  }

  .print\:shadow-0 {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }
}
